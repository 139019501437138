import useSWR from "swr";
import {API_URL, fetchSWR, post} from "../apiClient";
import {User} from "./user-info";

export interface Area {
    "pos_x": number,
    "pos_y": number,
    "building_level": number,
    "rent_claim_at": string,
    "has_elevator": boolean,
    "has_conditioner": boolean,
    "has_parking": boolean,
    "has_pool": boolean,
    "has_terrace": boolean,
    "has_shop": boolean,
    "has_laundry": boolean
    "available_to_claim": number
    laundry_setup_price: number
    shop_setup_price: number
    terrace_setup_price: number
    pool_setup_price: number
    parking_setup_price: number
    conditioner_setup_price: number
    elevator_setup_price: number
    rent_per_hour_amount: number
    max_claim_amount: number
}


export const useAreas = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/areas',
        (url: any) => fetchSWR<Area[]>({input: url}), {
            revalidateOnFocus: false
        }
    );
}


interface AreaParams {
    pos_x: number;
    pos_y: number;
    skip?: boolean;
}

export const useArea = ({pos_x, pos_y, skip}: AreaParams) => {
    return useSWR(skip ? undefined :`/area?pos_x=${pos_x}&pos_y=${pos_y}` ,
        (url: any) => fetchSWR<Area>({input: url}), {
            revalidateOnFocus: false
        }
    );
}


export interface Boost {
    "name": string
    "level": number
    "next_level_price": number
}

export const useBoosts = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/areas/boosts',
        (url: any) => fetchSWR<Boost[]>({input: url}), {
            revalidateOnFocus: false
        }
    );
}


export interface BuildingConfig {
    pos_x: number;
    pos_y: number;
    combo_count: number; // сколько было сделано комбо (идеально один на один)
    high_quality_count: number; // сколько поставили с прилеганием к предыдущему блоку на 90% и больше
    medium_quality_count: number; // 50% и больше
    low_quality_count: number; // меньше 50%
};


export async function buildHandler(data: BuildingConfig) {
    const response = await post({
        input: '/areas/build',
        data,
        init: {
            headers: {
                'Content-Type': 'application/json'
            }
        }

    });
    return (await response.json()) as Area
}


interface ClaimConfig {
    pos_x: number;
    pos_y: number;
}


export async function claimHandler(data: ClaimConfig) {
    const response = await post({
        input: '/areas/claim',
        data,
        init: {
            headers: {
                'Content-Type': 'application/json'
            }
        }

    });
    return (await response.json()) as ClaimResult
}


export async function demolishHandler(data: ClaimConfig) {
    const response = await post({
        input: '/areas/demolish',
        data,
        init: {
            headers: {
                'Content-Type': 'application/json'
            }
        }

    });
    return (await response.json()) as Area
}


interface ClaimResult {
    area: Area;
    claimed: number;
}


interface BoostAreaParams {
    "name": string,
    "pos_x": number,
    "pos_y": number

}

export async function boostAreaHandler(data: BoostAreaParams) {
    const response = await post({
        input: '/areas/boosts/setup',
        data,
        init: {
            headers: {
                'Content-Type': 'application/json'
            }
        }

    });
    return (await response.json()) as Area
}

export async function boostHandler(data: { name: string }) {
    const response = await post({
        input: '/areas/boosts',
        data,
        init: {
            headers: {
                'Content-Type': 'application/json'
            }
        }

    });
    return (await response.json()) as Boost[]
}