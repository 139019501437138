import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {Info} from "../info";
import {BorderedTitle} from "../bordered-title";
import {useNavigate} from "react-router-dom";
import {Area, buildHandler} from "../../api/requests/area";
import {useAreasStore} from "../../store/areas-store";
import {useUserStore} from "../../store/user-store";
import {use} from "matter-js";
import {ColorButton} from "../color-button";
import React, {useEffect, useMemo} from "react";
import {ActionModal} from "../action-modal";
import {TowerInfo} from "../tower-info";
import {CloseLevelIcon} from "../icons/close-level";
import {usePositionStore} from "../../store/position-store";

export const MainView = () => {

    const {areas} = useAreasStore();
    const {user} = useUserStore()
    const nav = useNavigate()


    const [activeArea, setActiveArea] = React.useState<Area | null>(null)

    const [isBuildModalOpen, setIsBuildModalOpen] = React.useState(false)

    const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false)
    const {setPosition} = usePositionStore()

    const rentPerHourOffAllAreas = useMemo(() => {
        return areas?.reduce((acc, area) => {
            return acc + area.rent_per_hour_amount
        }, 0) || 0
    }, [areas])

    return (
        <Container>
            <Col>
                <Title>
                    Town balance
                </Title>
                <BorderedTitle title={user?.balance?.toString() || '0'}/>
                <Row>

                    <Info title={'Blox level'} value={user?.block_level || 0}/>
                    <Info title={'Rent per hour'} value={rentPerHourOffAllAreas}/>

                </Row>
                <Matrix>
                    {areas?.map((area, index) => {
                        if (area.building_level > 0) {
                            return (
                                <OpenLevelIcon
                                    src={  `/images/roof/${area.building_level}.png`}
                                    onClick={async () => {
                                        setActiveArea(area)
                                        setIsInfoModalOpen(true)
                                    }}
                                    key={index}
                                />
                            );
                        }

                        return <CloseLevelIcon
                            active={(activeArea?.pos_x === area.pos_x && activeArea?.pos_y === area.pos_y)}
                            onClick={() => {
                                setActiveArea(area)
                                setIsBuildModalOpen(true)
                            }}
                            key={index}
                        />
                    })}
                </Matrix>

                <ButtonRow>
                    {/*{activeArea?.building_level === 0 ? (*/}
                    {/*        <ColorButton*/}
                    {/*            onClick={() => {*/}
                    {/*                nav(`/game?x=${activeArea?.pos_x}&y=${activeArea?.pos_y}`)*/}
                    {/*            }}*/}
                    {/*            color={'linear-gradient(0deg, #DB9900 0%, #FFC71E 99.86%)'}*/}
                    {/*            borderColor={'#FFB500'}*/}
                    {/*            text={'Build new tower'}*/}
                    {/*        />*/}
                    {/*    ) :*/}
                    {/*    <>*/}

                    {/*<ColorButton*/}
                    {/*    onClick={() => setIsClaimModalOpen(true)}*/}
                    {/*    color={'linear-gradient(0deg, #28B236 0%, #1CED63 99.86%)'}*/}
                    {/*    borderColor={'#76FF7C'}*/}
                    {/*    text={'CLAIM RENT'}*/}
                    {/*/>*/}
                    {/*        <ColorButton*/}
                    {/*            onClick={() => setIsUpgradeModalOpen(true)}*/}
                    {/*            color={'linear-gradient(0deg, #DB9900 0%, #FFC71E 99.86%)'}*/}
                    {/*            borderColor={'#FFB500'}*/}
                    {/*            text={'Upgrade'}*/}
                    {/*        />*/}
                    {/*        <ColorButton*/}
                    {/*            onClick={() => setIsDemolishModalOpen(true)}*/}
                    {/*            color={'linear-gradient(0deg, #B2282D 0%, #ED1C24 99.86%)'}*/}
                    {/*            borderColor={'#9B0000'}*/}
                    {/*            text={'demolish'}*/}
                    {/*        />*/}
                    {/*    </>*/}
                    {/*}*/}
                </ButtonRow>
                <BottomNav/>

            </Col>
            {/*<ActionModal*/}
            {/*    onClick={() => {*/}
            {/*    }}*/}
            {/*    color={'linear-gradient(0deg, #B2282D 0%, #ED1C24 99.86%)'}*/}
            {/*    borderColor={'#9B0000'}*/}
            {/*    text={'demolish'}*/}
            {/*    title={'demolish the building'}*/}
            {/*    open={isDemolishModalOpen}*/}
            {/*    onClose={() => setIsDemolishModalOpen(false)}*/}
            {/*/>*/}
            <ActionModal
                onClick={() => {
                    setPosition({pos_x: activeArea?.pos_x || 0, pos_y: activeArea?.pos_y || 0})
                    nav(`/game`)
                }}
                color={'linear-gradient(0deg, #DB9900 0%, #FFC71E 99.86%)'}
                borderColor={'#FFB500'}
                text={'Build'}
                title={'Build new tower'}
                open={isBuildModalOpen}
                onClose={() => setIsBuildModalOpen(false)}
            />

            {/*    <ClaimAvailableTxt>*/}
            {/*        Available to claim:*/}
            {/*        <ClaimAvailableCntTxt>*/}
            {/*            {`${activeArea?.available_to_claim} BLOX`}*/}
            {/*        </ClaimAvailableCntTxt>*/}
            {/*    </ClaimAvailableTxt>*/}
            {/*</ActionModal>*/}
            <TowerInfo
                area={activeArea}
                open={isInfoModalOpen}
                onClose={() => {
                    setActiveArea(null)
                    setIsInfoModalOpen(false)
                }}/>
        </Container>
    )
}


const OpenLevelIcon = styled.img`
    width: 75px;
    height: 75px;
`

const Title = styled.div`
    font-family: Roboto;
    // uppercase
    //font-style: uppercase;
    text-transform: uppercase;
    color: white;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 2px;
    text-align: left;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    scrollbar-width: none;
    width: 100%;
    background-image: url('/images/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    box-sizing: border-box;
`


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    padding: 20% 0 0 0;
    gap: 15px;
    box-sizing: border-box;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    gap: 10px;
`

const Matrix = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 7px;
    grid-template-areas:
          "Column Column Column Column"
          "Column Column Column Column"
          "Column Column Column Column";

`

const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
`

const ClaimAvailableTxt = styled.div`
    color: #FFF;
    font-size: 18px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.078px;
    display: flex;
`
const ClaimAvailableCntTxt = styled.div`
    color: #FFC030;
    font-size: 18px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.078px;
`