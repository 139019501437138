import useSWR from "swr";
import {fetchSWR, post} from "../apiClient";
import {Area, BuildingConfig} from "./area";


export interface Channel {
    id: number
    invite_link: string
    is_available: boolean
    reward: number
    title: string

}


export const useChannels = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/channels',
        (url: any) => fetchSWR<Channel[]>({input: url}), {
            revalidateOnFocus: false
        }
    );
}


export interface Link {
    id: number
    is_available: boolean
    link: string
    name: string
    reward: number
}


export const useLinks = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/links',
        (url: any) => fetchSWR<Link[]>({input: url}), {
            revalidateOnFocus: false
        }
    );
}

interface LinkResponse {
    new_balance: number
}

export async function linkHandler(id: number) {
    const response = await post({
        input: '/links/' + id,
        init: {
            headers: {
                'Content-Type': 'application/json'
            }
        }

    });
    return (await response.json()) as LinkResponse
}





