import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {BorderedTitle} from "../bordered-title";
import {ThreeDBlockIcon} from "../icons/sd-block";
import {PlusIcon} from "../icons/plus";
import {CLockIcon} from "../icons/clock";
import {PlayIcon} from "../icons/play-icon";
import {useChannelsStore} from "../../store/channels-store";
import {useLinksStore} from "../../store/links-store";
import {Link} from "react-router-dom";
import {postEvent} from "@telegram-apps/sdk";
import {tgLink} from "../utils/tg-link";
import {useFriends} from "../../api/requests/user-info";
import {useFriendsStore} from "../../store/friends-store";
import {CheckedIcon} from "../icons/checked-icon";
import React from "react";
import {useUserStore} from "../../store/user-store";
import {linkHandler} from "../../api/requests/channels";


export const EarnView = () => {

    const {channels, setChannels} = useChannelsStore()
    const {links, setLinks} = useLinksStore()
    const {friends} = useFriendsStore()

    const {setUser} = useUserStore()
    return (

        <Wrapper>
            <Container>
                <div style={{width: '100%', height: 64}}/>
                <Col>
                    <BorderedTitle title={'earn'}/>
                    <List>
                        <SubTitle>
                            Daily
                        </SubTitle>
                        <DaylyItem onClick={() => tgLink(friends?.friends_share_link || '')}/>
                    </List>
                    <List style={{marginBottom: 25}}>
                        <SubTitle>
                            Channels
                        </SubTitle>
                        {channels?.map((channel, index) => (
                            <EarnItem
                                isAvailible={channel.is_available}
                                onClick={() => {
                                    setChannels(channels.map((ch) => {
                                        if (ch.id === channel.id) {
                                            ch.is_available = false
                                        }
                                        return ch
                                    }))
                                    tgLink(channel.invite_link)
                                }}
                                key={index} channel={channel.title}
                                value={channel.reward}/>
                        ))}
                    </List>
                    <List style={{paddingBottom: 100}}>
                        <SubTitle>
                            Bots
                        </SubTitle>
                        {links?.map((channel, index) => (
                            <EarnItem
                                isAvailible={channel.is_available}
                                onClick={async () => {


                                    const response = await linkHandler(channel.id)

                                    setUser((user) => {
                                        return {
                                            ...user,
                                            balance: response.new_balance
                                        }
                                    })

                                    setLinks(links.map((ch) => {
                                        if (ch.id === channel.id) {
                                            ch.is_available = false
                                        }
                                        return ch
                                    }))
                                    tgLink(channel.link)
                                }}
                                key={index}
                                channel={channel.name}
                                value={channel.reward}/>
                        ))}
                    </List>
                </Col>
            </Container>
            <BottomNav/>
        </Wrapper>

    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-image: url('/images/dark-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow-y: auto; /* Enable vertical scrolling */
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto; /* Ensure container can scroll */
    width: 100%;
    align-items: center;
    position: relative;
    justify-content: center;
    gap: 20px;
    padding-top: 54px;
    padding-bottom: 120px; /* Add padding to ensure the last items are visible */

`;


const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    height: 100%;


`


const List = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;

`


interface EarnItemProps {
    channel: string;
    value: number
    onClick: () => void
    isAvailible?: boolean
}

const EarnItem = ({channel, isAvailible, value, onClick}: EarnItemProps) => {
    return (
        <ThreeDContainer isAvailible={isAvailible} onClick={onClick}>
            <ItemRow>
                {/*<ThreeDBlockIcon/>*/}
                <EarnCol>
                    <ChannelText>
                        {channel}
                    </ChannelText>
                    <ValueText>
                        +{value} BLOXX
                    </ValueText>
                </EarnCol>
                {!isAvailible ? <CheckedIcon/> : <PlusIcon/>}
            </ItemRow>
        </ThreeDContainer>
    )
}


const ThreeDContainer = styled.div<{ isAvailible?: boolean }>`
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e87c18;
    background: ${props => !props.isAvailible ? 'rgba(255, 192, 48, 1)' : 'rgba(15, 112, 136, 0.80)'};
    box-shadow: 0px 5px 0px 0px #C24606;
    display: flex;
    width: 100%;
    padding: 8px 0;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    text-decoration: none;
`


const DaylyItem = ({onClick}: { onClick?: () => void }) => {
    return (
        <DaylyThreeDContainer onClick={onClick}>
            <ItemRow>
                <CLockIcon/>
                <DaylyText>
                    Invite 3 or more friends and earn 50.000 BLOXX
                </DaylyText>
                <PlayIcon/>
            </ItemRow>
        </DaylyThreeDContainer>
    )
}


const DaylyThreeDContainer = styled.div`
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e87c18;
    background: #FFB500;
    box-shadow: 0px 5px 0px 0px #C24606;
    display: flex;
    width: 100%;
    padding: 8px 0;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    margin-bottom: 25px;
`

const DaylyText = styled.div`
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    text-shadow: 1px 1px #C96814,
    -1px -1px 0 #C96814,
    1px -1px 0 #C96814,
    -1px 1px 0 #C96814,
    0px 1px 0 #C96814,
    1px 0px 0 #C96814,
    -1px 0px 0 #C96814,
    0px -1px 0 #C96814;
    color: white;
    text-align: left;
    width: 70%;
`

const ChannelText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.4px;
    text-transform: uppercase;

`


const ValueText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.23px;
`

const ItemRow = styled.div`
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
`

const EarnCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    width: 80%;
`

const SubTitle = styled.div`
    color: #FFF;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
    text-transform: uppercase;
`


