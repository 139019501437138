import styled from "styled-components";
import {BottomNav} from "../bottom-nav";
import {BorderedTitle} from "../bordered-title";
import {ThreeDContainer} from "../3d-container";
import {ThreeDBlockIcon} from "../icons/sd-block";
import {PlusIcon} from "../icons/plus";
import {CLockIcon} from "../icons/clock";
import {PlayIcon} from "../icons/play-icon";
import FriendList from "../frien-list";
import {Info} from "../info";
import {TGIcon} from "../icons/TG";
import {useFriendsStore} from "../../store/friends-store";
import {tgLink} from "../utils/tg-link";

export const InviteView = () => {
    const {friends} = useFriendsStore()

    return (

        <Wrapper>
            <Container>
                <div style={{width: '100%', height: 64}}/>
                <Col>
                    <BorderedTitle title={'invite'}/>
                    <InviteFriendItem onClick={() => tgLink(friends?.friends_share_link || '')}/>
                    <Row>
                        <Info title={'Reffers'} value={friends?.friends_count || 0}/>
                        <Info title={'Subreffers'} value={friends?.sub_friends_count || 0}/>
                    </Row>
                    <Row>
                        <Info title={'Reffers profit'} value={friends?.friends_profit || 0}/>
                        <Info title={'Subreffers profit'} value={friends?.sub_friends_profit || 0}/>
                    </Row>

                    {/*<FriendList*/}
                    {/*    totalPoints={10}*/}
                    {/*    friends={[{*/}
                    {/*        name: 'Friend 1',*/}
                    {/*        points: 2*/}
                    {/*    },*/}
                    {/*        {*/}
                    {/*            name: 'Friend 1',*/}
                    {/*            points: 2*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*            name: 'Friend 1',*/}
                    {/*            points: 6*/}
                    {/*        }*/}
                    {/*    ]}*/}

                    {/*/>*/}
                    <BottomNav/>
                </Col>
            </Container>
        </Wrapper>

    )
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;

`
const Title = styled.div`
  font-family: Roboto;
  // uppercase
  //font-style: uppercase;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 2px;
  text-align: left;
`


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url('/images/dark-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow-y: auto; /* Enable vertical scrolling */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto; /* Ensure container can scroll */
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
  gap: 20px;
  padding-top: 54px;
  padding-bottom: 120px; /* Add padding to ensure the last items are visible */

`;


const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 320px;
  width: 100%;
  height: 100%;
`


const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

`
const InviteItem = () => {
    return (
        <ThreeDContainer borderColor={'#e87c18'}>
            <ItemRow>
                <ThreeDBlockIcon/>
                <EarnCol>
                    <ChannelText>
                        Channel
                    </ChannelText>
                    <ValueText>
                        +12.000 BLOXX
                    </ValueText>
                </EarnCol>
                <PlusIcon/>
            </ItemRow>
        </ThreeDContainer>
    )
}

const InviteFriendItem = ( {onClick}: {onClick?: () => void} ) => {

    return (
        <ThreeDContainer
            onClick={onClick}
            borderColor={'#e87c18'} mainColor={'#FFB500'}>
            <ItemRow>
                <TGIcon/>
                <InviteCol>
                    <InviteFriendText>
                        Invite Friends
                    </InviteFriendText>
                    <InfoRow>
                        up to <Bold>30 000</Bold> for friend
                    </InfoRow>
                </InviteCol>
                <PlayIcon/>
            </ItemRow>
        </ThreeDContainer>
    )
}


const Bold = styled.div`
  font-weight: 700;
  margin-left: 4px;
  margin-right: 4px;
`

const InviteCol = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

`


const InfoRow = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.23px;
  display: flex;
`

const InviteFriendText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  text-shadow: 1px 1px #C96814,
    -1px -1px 0 #C96814,
    1px -1px 0 #C96814,
  -1px 1px 0 #C96814,
  0px 1px 0 #C96814,
  1px 0px 0 #C96814,
  -1px 0px 0 #C96814,
    0px -1px 0 #C96814;
  color: white;
  text-align: left;
`

const ChannelText = styled.div`
  color: #FFF;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.4px;
  text-transform: uppercase;

`


const ValueText = styled.div`
  color: #FFF;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.23px;
`

const ItemRow = styled.div`
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
`

const EarnCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  width: 80%;
`

const SubTitle = styled.div`
  color: #FFF;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 125% */
  text-transform: uppercase;
  margin-bottom: 8px;
`


