import styled, {keyframes} from "styled-components";
import {IconProps} from "../model";
import {BorderedTitle} from "./bordered-title";
import React, {useEffect, useState} from "react";
import {ColorButton} from "./color-button";
import {ThreeDBlockIcon} from "./icons/sd-block";
import {PlusIcon} from "./icons/plus";
import {Area, Boost, boostAreaHandler, claimHandler, demolishHandler, useArea} from "../api/requests/area";
import {useAreasStore} from "../store/areas-store";
import {useUserStore} from "../store/user-store";
import {useBoostsStore} from "../store/boosts-store";
import {CheckedIcon} from "./icons/checked-icon";
import {getBoostIcon} from "./utils/getBoostIcon";
import {useSpring, animated} from 'react-spring';
import {ActionModal} from "./action-modal";


interface TowerInfoProps {
    open: boolean;
    onClose?: () => void;
    area: Area | null;
}

export const TowerInfo = ({open, onClose, area: propArea}: TowerInfoProps) => {


    const {data: areaFromApi, mutate} = useArea({
        pos_x: propArea?.pos_x as number,
        pos_y: propArea?.pos_y as number,
        skip: !propArea
    })
    const [selectedBoost, setSelectedBoost] = React.useState<Boost | null>(null)

    const [isDemolishModalOpen, setIsDemolishModalOpen] = React.useState(false)
    const {setAreas} = useAreasStore()
    const {user, setUser} = useUserStore()
    const {boosts} = useBoostsStore();
    const [area, setArea] = useState<Area | null>()

    useEffect(() => {
        if (areaFromApi) {
            setArea(areaFromApi)
        }
    }, [areaFromApi]);

    if (!user) {
        return null
    }


    const onClaim = async () => {
        if (!area) return
        const resp = await claimHandler({
            pos_x: area.pos_x,
            pos_y: area.pos_y
        })
        await mutate()
        setAreas((prevAreas) => {
                return prevAreas?.map((prevArea) => {
                        if (prevArea.pos_x === area.pos_x && prevArea.pos_y === area.pos_y) {

                            return resp.area
                        }
                        return prevArea
                    }
                )
            }
        )
        setArea(resp.area)
        setUser((prevUser) => {
            return {
                ...prevUser,
                balance: prevUser?.balance + resp.claimed
            }
        })

    }


    const onDemolish = async () => {
        if (!area) return
        const resp = await demolishHandler({
            pos_x: area.pos_x,
            pos_y: area.pos_y
        })
        setAreas((prevAreas) => {
                return prevAreas?.map((prevArea) => {
                        if (prevArea.pos_x === area.pos_x && prevArea.pos_y === area.pos_y) {
                            return resp
                        }
                        return prevArea
                    }
                )
            }
        )
        setIsDemolishModalOpen(false)
        onClose && onClose()
    }


    const onBoost = async (name: string) => {
        const res = await boostAreaHandler({
            name,
            pos_x: area?.pos_x as number,
            pos_y: area?.pos_y as number
        })
        setAreas((prevAreas) => {
                return prevAreas?.map((prevArea) => {
                        if (prevArea.pos_x === area?.pos_x && prevArea.pos_y === area?.pos_y) {
                            setArea(res)
                            return res
                        }
                        return prevArea
                    }
                )
            }
        )
        setUser((prevUser) => {
            return {
                ...prevUser,
                // @ts-ignore
                balance: prevUser?.balance - area[name + '_setup_price'] as unknown as number
            }
        })


    }


    if (!open) return null;

    return (
        <Wrapper>
            <Container>
                <FakeDiv/>
                <CLoseIcon onClick={onClose}/>
                <BorderedTitle
                    style={{marginTop: 32, marginBottom: 16}}
                    title={'Tower info'}/>
                <Row>
                    <HourContainer>
                        <HourBoxxValue>
                            {area?.rent_per_hour_amount || 0} bloxx/ <HourBoxx>
                            HOUR
                        </HourBoxx>
                        </HourBoxxValue>
                    </HourContainer>
                    <LevelContainer>
                        <HourBoxxValue>
                            <HourBoxx>
                                {area?.building_level || 0} {" "}
                                LEVEL
                            </HourBoxx>
                        </HourBoxxValue>
                    </LevelContainer>
                </Row>

                <ProgressBar rent_per_hour_amount={area?.rent_per_hour_amount!!}
                             available_to_claim={area?.available_to_claim as number}
                             max_claim_amount={area?.max_claim_amount!!}/>
                <div style={{height: '100%', width: '100%'}}>
                    <ColorButton
                        onClick={onClaim}
                        color={'linear-gradient(0deg, #28B236 0%, #1CED63 99.86%)'}
                        borderColor={'#76FF7C'}
                        text={'CLAIM RENT'}
                    />
                </div>
                <List style={{marginBottom: 25}}>
                    <SubTitle>
                        Boost
                    </SubTitle>
                    {boosts?.map((boost, index) => {

                        if (boost.name === 'block') {
                            return null
                        }

                        return (
                            <BoostItem
                                icon={getBoostIcon(boost.name)}
                                onClick={() => setSelectedBoost(boost)}
                                key={index}
                                hasbuileded={area ? area['has_' + boost?.name as keyof Area] as boolean : false}
                                channel={boost.name}
                                value={area ? area[boost?.name + '_setup_price' as keyof Area] as number : 0}/>
                        )
                    })}
                </List>
                <List style={{marginBottom: 25}}>
                    <SubTitle>
                        DEMOLISH
                    </SubTitle>
                    <ColorButton
                        onClick={() => setIsDemolishModalOpen(true)}
                        color={'linear-gradient(0deg, #B2282D 0%, #ED1C24 99.86%)'}
                        borderColor={'#9B0000'}
                        text={'demolish'}
                    />
                </List>
            </Container>
            {area &&
              <ActionModal
                onClick={async () => {
                    if (selectedBoost && user.balance >= area[selectedBoost.name + '_setup_price' as keyof Area]) {
                        await onBoost(selectedBoost.name)
                        setSelectedBoost(null)
                    }
                }}
                color={
                    user.balance >= area[selectedBoost?.name + '_setup_price' as keyof Area]
                      ? 'linear-gradient(0deg, #28B236 0%, #1CED63 99.86%)'
                      : 'linear-gradient(0deg, #A9A9A9 0%, #D3D3D3 99.86%)'
                }
                borderColor={
                    user.balance >= area[selectedBoost?.name + '_setup_price' as keyof Area]
                      ? '#76FF7C'
                      : '#B0B0B0'
                }
                text={
                    user.balance >= area[selectedBoost?.name + '_setup_price' as keyof Area]
                      ? 'Upgrade for ' + area[selectedBoost?.name + '_setup_price' as keyof Area] + ' BLOXX'
                      : 'Insufficient funds'
                }
                subText={'Please confirm that you want to setup ' + selectedBoost?.name}
                title={selectedBoost?.name || ''}
                open={selectedBoost !== null}
                onClose={() => setSelectedBoost(null)}
              />
            }
            <ActionModal
                onClick={onDemolish}
                color={'linear-gradient(0deg, #B2282D 0%, #ED1C24 99.86%)'}
                borderColor={'#9B0000'}
                text={'demolish'}
                title={'demolish the building'}
                open={isDemolishModalOpen}
                onClose={() => setIsDemolishModalOpen(false)}
            />
        </Wrapper>
    )
}


const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const FakeDiv = styled.div`
    width: 100%;
    height: 32px;
`


const List = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

`


interface EarnItemProps {
    channel: string;
    value: number
    hasbuileded: boolean
    onClick?: () => void
    icon?: React.ReactNode
}

const BoostItem = ({channel, value, hasbuileded, icon, onClick}: EarnItemProps) => {
    return (
        <ThreeDContainer
            onClick={!hasbuileded ? onClick : undefined}

            hasbuileded={hasbuileded}>
            <ItemRow>
                <Relativ>
                    {icon}
                    <ThreeDBlockIcon/>
                </Relativ>
                <BoostCol>
                    <ChannelText>
                        {channel}
                    </ChannelText>
                    <ValueText>
                        +{value} BLOXX
                    </ValueText>
                </BoostCol>
                {hasbuileded ? <CheckedIcon/> : <PlusIcon/>}
            </ItemRow>
        </ThreeDContainer>
    )
}

const Relativ = styled.div`
    position: relative;
`


const ThreeDContainer = styled.div<{ hasbuileded: boolean }>`
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e87c18;
    background: ${props => props.hasbuileded ? 'rgba(255, 192, 48, 1)' : 'rgba(15, 112, 136, 0.80)'};
    //background: rgba(15, 112, 136, 0.80);
    box-shadow: 0px 5px 0px 0px #C24606;
    display: flex;
    width: 100%;
    padding: 12px 0;
    flex-direction: column;
    align-items: center;
    gap: 3px;
`


const ChannelText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.4px;
    text-transform: uppercase;

`


const ValueText = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    letter-spacing: -0.23px;
`

const ItemRow = styled.div`
    display: flex;
    padding: 0px 16px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
`

const BoostCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
    width: 80%;
`

const SubTitle = styled.div`
    color: #FFF;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 125% */
    text-transform: uppercase;
`


const Wrapper = styled.div`
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(7px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1000000;
`

const Container = styled.div`
    position: relative;
    border-radius: 12px;
    border: 5px solid #F9D838;
    border-bottom: 0px solid transparent;

    background: #0F7088;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 90%;
    padding: 0px 28px 32px 28px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    overflow: auto;
    gap: 10px;
    animation: ${fadeIn} 0.5s ease-in-out;

`


const CloseIconSWG = (props: IconProps) => {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L10 8.58579L15.2929 3.29289C15.6834 2.90237 16.3166 2.90237 16.7071 3.29289C17.0976 3.68342 17.0976 4.31658 16.7071 4.70711L11.4142 10L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L10 11.4142L4.70711 16.7071C4.31658 17.0976 3.68342 17.0976 3.29289 16.7071C2.90237 16.3166 2.90237 15.6834 3.29289 15.2929L8.58579 10L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z"
                  fill="white"/>
        </svg>
    )
}


const CLoseIcon = styled(CloseIconSWG)`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`

const HourContainer = styled.div`
    display: flex;
    width: 170px;
    height: 26px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid #F1AC00;
    background: #1287A5;
`

const LevelContainer = styled.div`
    display: flex;

    height: 26px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid #F1AC00;
    background: #1287A5;
`

const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 16px;
`


const HourBoxxValue = styled.div`
    color: #F1AC00;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 208.333% */
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

`
const HourBoxx = styled.div`
    color: #fff;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px; /* 208.333% */
    text-transform: uppercase;;
`


interface ProgressBarProps {
    available_to_claim: number;
    max_claim_amount: number;
    rent_per_hour_amount: number;
}

const ProgressBar = ({available_to_claim, max_claim_amount, rent_per_hour_amount}: ProgressBarProps) => {
    const [currentClaim, setCurrentClaim] = useState(available_to_claim);

    useEffect(() => {
        setCurrentClaim(available_to_claim || 0);
    }, [available_to_claim]);

    const animatedClaim = useSpring({
        value: currentClaim,
        from: {value: 0},
        config: {duration: 1000}, // Control the speed of the animation
    });

    useEffect(() => {
        const incrementPerSecond = rent_per_hour_amount / 3600; // Convert hourly rate to per second

        const interval = setInterval(() => {
            setCurrentClaim(prev => {
                const newClaim = prev + incrementPerSecond;
                if (newClaim >= max_claim_amount) {
                    clearInterval(interval);
                    return max_claim_amount;
                }
                return newClaim;
            });
        }, 1000);

        return () => clearInterval(interval); // Cleanup on unmount
    }, [rent_per_hour_amount, max_claim_amount, available_to_claim]);

    return (

        <PRelativ>
            <PContainer>
                <PProgress percent={(currentClaim / max_claim_amount) * 102}/>
            </PContainer>
            <PInfo>
                <PInfoCol>
                    <PBalance>
                        BALANCE
                    </PBalance>
                    <PBalanceValueTop>
                        <animated.div>{animatedClaim.value.to((val) => val.toFixed(2))}</animated.div>
                    </PBalanceValueTop>
                    <PBalanceValueBottom>
                        / {max_claim_amount}
                    </PBalanceValueBottom>
                </PInfoCol>
            </PInfo>

        </PRelativ>
    )

}


const PBalanceValueTop = styled.div`
    color: #FFC030;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px; /* 83.333% */
    letter-spacing: -0.078px;
    text-transform: uppercase;
`

const PBalanceValueBottom = styled.div`
    color: #FFC030;
    font-family: Roboto;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px; /* 83.333% */
    letter-spacing: -0.078px;
    text-transform: uppercase;
`

const PBalance = styled.div`
    color: #FFF;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px; /* 100% */
    text-transform: uppercase;
`


const PRelativ = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 6px;
    margin-top: 6px;
`


const PContainer = styled.div`
    border-radius: 16px;
    border: 2px solid #E87D18;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #1287A5;
    box-shadow: 0px 3px 0px 0px #C24606;
    width: 83%;
    height: 36px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
`

const PProgress = styled.div<{ percent: number }>`
    width: ${props => props.percent ? props.percent + '%' : '0%'};
    height: 36px;
    flex-shrink: 0;
    border-radius: 14px;
    background: linear-gradient(180deg, #FEC667 0%, #FBAD23 100%), #26BFE5;
    transition: width 1s ease-in-out;

`

const PInfo = styled.div`
    display: flex;
    height: 36px;
    padding: 4px 16px 4px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 12px;
    border: 2px solid #E87D18;
    background: #1287A5;
    position: absolute;
    top: -4px;
    right: -11px;`


const PInfoCol = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`