import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {on} from "@tma.js/sdk-react";
import {postEvent} from "@telegram-apps/sdk";

export const BackButtonProvider = ({children} : {children: React.ReactNode}) => {
    const navigate = useNavigate();
    const loaction = useLocation()





    const backButtonHandler = () => {
        const removeListener = on('back_button_pressed', payload => {
                 console.log('back_button_pressed', loaction.pathname)
                navigate('/');
         });
        return removeListener;
    }


    useEffect(() => {
        if(loaction.pathname === '/'){
            postEvent('web_app_setup_back_button', {is_visible: false});
        } else {
            postEvent('web_app_setup_back_button', {is_visible: true});

        }
    }, [loaction.pathname]);

    useEffect(() => {
        const removeListener = backButtonHandler();
        return () => {
            removeListener();
        };
    }, []);

    return <>{children}</>
 }